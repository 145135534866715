import React from 'react'
import LandingPage from 'templates/LandingPage'
import PropTypes from 'prop-types'

const Index = ({ location }) => {
  return (
    <LandingPage
      location={location}
      pageContext={
        {
          title: 'Caldeira Guesthouse & Surfcamp - Fajã da Caldeira de Santo Cristo',
          description: 'Nature, simplicity, tranquility. Discover the charm, the waves and the peacefulness of Fajã da Caldeira de Santo Cristo.'
        }
      }
    />
  )
}

Index.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

export default Index
